"use client";
import { AiFillInfoCircle, AiFillWarning } from "../../react-icons/ai";
import { IoMdCheckmarkCircle, IoMdCloseCircle } from "../../react-icons/io";
import { CloseButton } from "../buttons";
import { motion, useAnimation } from "framer-motion";
import classNames from "classnames";
import { ToastType } from "./ToastTypes";
import { useEffect, useRef } from "react";

type Props = {
  toast: ToastType;
  cb: (id: number) => void;
  bottom?: number;
  top?: number;
  zIndex: number;
  toastIndex: number;
};

export function Toast({ toast, cb, bottom, top, zIndex, toastIndex }: Props) {
  const timeByCharacter = 100;
  const timeByWord = 500;
  const timeAdded = 500;
  const autoDeleteTime = (toast?.legend?.split(" ")?.length * timeByWord + timeAdded) || 5000;

  const toastRef = useRef(null);
  const progressRef = useRef(null);
  const controls = useAnimation();

  const variant = {
    enter: (toastIndex: number) => {
      return {
        zIndex,
        bottom: bottom ? bottom - 200 + "px" : "unset",
        top: top ? top - 200 + "px" : "unset",
        right: -toastIndex * 5 + 20,
        scale: 1 - toastIndex * 0.04,
      };
    },
    animation: (toastIndex: number) => {
      return {
        zIndex,
        bottom: bottom ? bottom + "px" : "unset",
        top: top ? top + "px" : "unset",
        right: -toastIndex * 5 + 20,
        scale: 1 - toastIndex * 0.04,
      };
    },
  };

  const startAnimation = (time: number) => controls.start({
    width: "0%",
    transition: {
      duration: time / 1000,
      type: "tween",
      ease: "easeOut"
    }
  });

  function removeToast() {
    cb(toast.id);
  }

  const handleMouseLeave = () => {
    if (toastIndex === 0) {
      const remainingTime =
        (progressRef.current.offsetWidth /
          progressRef.current.parentElement.offsetWidth) *
        autoDeleteTime;

      progressRef.current.style.animationPlayState = "running";
      toastRef.current = setTimeout(removeToast, remainingTime);
      startAnimation(remainingTime);
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(toastRef.current);

    controls.stop();
  };


  useEffect(() => {
    if (toastIndex === 0) {
      startAnimation(autoDeleteTime);
      toastRef.current = setInterval(removeToast, autoDeleteTime);

      return () => {
        clearInterval(toastRef.current);
      };
    }
  }, [toastIndex]);

  return (
    <motion.li
      key={`toast_${toast.id}`}
      className={classNames("toast", toast.type)}
      variants={variant}
      custom={toastIndex}
      animate={"animation"}
      initial={"enter"}
      exit={{ x: 500 }}
      transition={{
        duration: 0.15,
        type: "spring",
        damping: 12,
        stiffness: 70,
        restDelta: 0.001,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="progress">
        <motion.div
          ref={progressRef}
          className={"progress-bar"}
          initial={{ width: "100%" }}
          animate={controls}
        />
      </div>

      <div className={"icon"}>
        {toast.type === "fail" && <IoMdCloseCircle size={30} />}
        {toast.type === "info" && <AiFillInfoCircle size={30} />}
        {toast.type === "good" && <IoMdCheckmarkCircle size={30} />}
        {toast.type === "warning" && <AiFillWarning size={30} />}
      </div>

      <div className="description"> {toast.legend}</div>

      <CloseButton cb={removeToast} size={18} />
    </motion.li>
  );
}
