"use client";

// Redux
import { useAppSelector } from "@/redux/hooks";
import { useAppDispatch } from "@/redux/hooks";
import { setDarkmode, setWindowSize } from "@/redux/slices/StyleSlice";
import { closeToast } from "@/redux/slices/ToastSlice";
import { useEffect } from "react";

// Icons

// Components
import { ToastContainer } from "ui/components/toasts";
import { useWindowSize } from "utilities/hooks/useWindowSize";

export default function ToastWrapper() {
  const dispatch = useAppDispatch();
  const toasts = useAppSelector(state => state.toasts.toasts);
  const windowSize = useWindowSize();

  const cb = (id: number) => {
    dispatch(closeToast(id));
  };

  useEffect(() => {
    if (window) {
      dispatch(setDarkmode(document.documentElement.getAttribute("data-theme")));
    }
  }, []);

  useEffect(() => {
    if (windowSize) {
      dispatch(setWindowSize(windowSize));
    }
  }, [windowSize]);

  return <ToastContainer cb={cb} toasts={toasts} />;
}
