import { useEffect, useState } from "react";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 1920,
    height: 1080,
  });

  function handleResize() {
    setWindowSize({
      width: window?.innerWidth,
      height: window?.innerHeight,
    });
  }

  useEffect(() => {
    if (window) {
      setWindowSize({
        width: window?.innerWidth,
        height: window?.innerHeight,
      });

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return windowSize;
}

export default useWindowSize;
