import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/expert/components/layout/ToastWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/expert/redux/provider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/expert/styles/main.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/components/layout/copyright/Copyright.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/components/layout/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/components/layout/headerUserLayout/HeaderUserLayout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/app.scss");
