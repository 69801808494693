"use client";
import { Toast } from "./Toast";
import { ToastType } from "./ToastTypes";
import { AnimatePresence, } from "framer-motion";
import { useMediaQuery } from "../../../utilities/hooks/useMediaQuery";
import { useEffect, useRef, type JSX } from "react";

type Props = {
  toasts: ToastType[];
  cb: (id: number) => void;
};

export function ToastContainer({ toasts, cb }: Props): JSX.Element {
  const mediaQuery = useMediaQuery("500");
  const ref = useRef(null);

  function hideContainer() {
    if (toasts?.length < 1) {
      ref?.current?.hidePopover && ref?.current?.hidePopover()
    
    };
  }

  useEffect(() => {
    if (toasts?.length > 0) {
      ref?.current?.showPopover && ref?.current?.showPopover();
    }
  }, [toasts]);

  return (
    <div
      ref={ref}
      id="toast-container"
      // @ts-ignore
      popover="manual"
    >
      <AnimatePresence onExitComplete={hideContainer} >
        {toasts.map((toast: ToastType, key: number) => (
          <Toast
            toast={toast}
            cb={cb}
            key={`toast_${toast.id}`}
            toastIndex={key}
            zIndex={toasts.length - key}
            bottom={!mediaQuery && 20 + key * 10}
            top={mediaQuery && 20 + key * 10}
          />
        ))}
      </AnimatePresence>
    </div>
  );
}
